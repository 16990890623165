const config = {
    // basename: only at build time to set, and don't add '/' at end off BASENAME for breadcrumbs, also don't put only '/' use blank('') instead,
    // like '/berry-material-react/react/default'
    basename: '',
    defaultPath: '/dashboard/default',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 4
};

export default config;

// export const APIURI = 'https://api.escapelygames.com/';
// export const REDIRECTURI = 'http://escapelygames.com/';

// export const APIURI = 'http://ec2-18-217-152-232.us-east-2.compute.amazonaws.com:5000/';
export const APIURI = 'https://api-escapley.immodesta.com/';
export const REDIRECTURI = 'https://devuser.escapelygames.com/';
